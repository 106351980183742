import { db } from "./firebase";
import "firebase/firestore";
import { collection, QueryDocumentSnapshot, serverTimestamp, SnapshotOptions } from "firebase/firestore";
import { doc, setDoc } from "firebase/firestore"; 

export class Reaction {
    
    public created_at: Date;
    constructor(
        public comment: string,
    ){
        this.created_at = new Date();
    }

    public async add() {
        const ref = doc(collection(db, "reactions")).withConverter(reactionConverter);
        await setDoc(ref, new Reaction(this.comment));
    }
}

export const reactionConverter = {
    toFirestore: (reaction: Reaction) => {
        return {
            comment: reaction.comment,
            created_at: serverTimestamp()
        };
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot, options: SnapshotOptions) => {
        const data = snapshot.data(options);
        return new Reaction(data.comment);
    }
}