import React from 'react';
import './firebase'
import './App.css';
import './stars.css';
import './stars'
import { Reaction } from './reaction';

const addReaction = (e: React.MouseEvent<HTMLElement>) => {
  let id = e.currentTarget.getAttribute("id")
  let mess = "メッセージ1";
  if (id !== null && id === "2"){
    mess = "メッセージ2"
  }
  let reaction = new Reaction(mess);
  reaction.add();
}

function App() {
  return (
    <div className="App">
      <div className='footer'>
        <div className="wrap">
          <button className="button" onClick={addReaction} id="1">1</button>
          <button className="button" onClick={addReaction} id="2">2</button>
        </div>
      </div>
    </div>
  );
}

export default App;
